<template>
  <v-dialog
    :value="show"
    max-width="500px"
    @click:outside="$emit('cancel')"
  >
    <v-card>
      <v-card-title class="headline error white--text">
        Confirme!
      </v-card-title>
      <v-card-text class="pt-4 text-center">
        Tem certeza que deseja deletar este item?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <btn-cancel
          text
          @click="$emit('cancel')"
        />
        <v-btn
          color="error"
          :loading="loading"
          text
          @click="$emit('confirm')"
        >
          <v-icon class="mr-2">
            mdi-delete
          </v-icon>
          Deletar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DialogDelete',

    props: {
      show: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

  }
</script>
